import React from 'react';

function Star({ width = 15, className = '', color = '#000' }) {
  return (
    <svg width={width} height={width * (14 / 15)} className={className} viewBox="0 0 15 14">
      <path
        fill={color}
        d="M149.81,639a0.41,0.41,0,0,1,.39.27l1.58,4.38a0.4,0.4,0,0,0,.38.27l4.75,0.12a0.4,0.4,0,0,1,.38.28,0.39,0.39,0,0,1-.14.44l-3.78,2.83a0.39,0.39,0,0,0-.14.43l1.35,4.46a0.39,0.39,0,0,1-.15.44,0.42,0.42,0,0,1-.48,0L150,650.29a0.42,0.42,0,0,0-.47,0l-3.91,2.63a0.42,0.42,0,0,1-.48,0,0.39,0.39,0,0,1-.15-0.44l1.35-4.46a0.39,0.39,0,0,0-.14-0.43l-3.78-2.83a0.39,0.39,0,0,1-.14-0.44,0.4,0.4,0,0,1,.38-0.28l4.75-.12a0.4,0.4,0,0,0,.38-0.27l1.58-4.38A0.41,0.41,0,0,1,149.81,639Z"
        transform="translate(-142.31 -639)"
      />
    </svg>
  );
}

export default Star;
