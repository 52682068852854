import { useEffect } from 'react';
import { useState } from 'react';

export function useMatchMedia(query: string) {
  const [isMatching, setMatchingState] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    if (isMatching !== mediaQuery.matches) {
      setMatchingState(mediaQuery.matches);
    }

    function handleMediaQueryChange(e: MediaQueryListEvent) {
      setMatchingState(e.matches);
    }

    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, [isMatching, query]);

  return isMatching;
}
