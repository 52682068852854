import React from 'react';

function Reply({ width = 14, className = '', color = '#000' }) {
  return (
    <svg className={className} height={width} width={width} viewBox="0 0 14 14">
      <path
        fill={color}
        d="M13.837,6.084C13.945,6.163,14,6.262,14,6.381
      c0,0.132-0.055,0.23-0.163,0.297l-7,4.636c-0.055,0.04-0.123,0.06-0.205,0.06c-0.054,0-0.109-0.014-0.164-0.04
      c-0.136-0.065-0.205-0.171-0.205-0.316V8.5c-0.873-0.031-4.201,0.098-5.567,1.605c-0.11,0.122-0.191,0.198-0.327,0.198H0.287
      C0.124,10.264,0.028,10.151,0,9.967c0-1.599,0.491-2.899,1.474-3.903c1.092-1.136,2.688-1.736,4.79-1.803V1.745
      c0-0.146,0.068-0.251,0.205-0.317c0.137-0.079,0.259-0.072,0.369,0.02L13.837,6.084z"
      />
    </svg>
  );
}

export default Reply;
