'use client';
import React, { useMemo } from 'react';

import { usePathname } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Hyperlink } from '@bloom/ui/components/Hyperlink';
import { emptyArray } from '@bloom/ui/utils/empty-value';

import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import CdnImage from '@bloom/library/components/ProgressiveImage/CdnImage';
import LeaveReview from '@bloom/library/components/Review/LeaveReview';
import Reviews from '@bloom/library/components/Review/Reviews';
import { renderTiptapToHtml } from '@bloom/library/components/Tiptap/template.server';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './About.module.scss';

const About: React.FC = () => {
  const pathname = usePathname();

  const [isReviewModalOpen, { setFalse: closeReviewModal, setTrue: openReviewModal }] = useToggle(
    pathname === '/review'
  );
  const [isCommentsShown, { setFalse: hideComments, setTrue: showComments }] = useToggle();

  const { publicAccount } = usePublicAccountByCustomUrl();

  const artistStatement = publicAccount?.artistStatement;
  const aboutPageContent = publicAccount?.aboutPageContent || '';
  const comments = emptyArray;
  const introSummary = publicAccount?.introSummary || '';
  const personalExperience = publicAccount?.personalExperience;

  const websiteSettings = publicAccount?.settings.websiteSettings;

  const { reviewsSectionOn = true } = websiteSettings;
  const { aboutImages, listItems, listName } = publicAccount || {
    aboutImages: [],
    listItems: [],
    listName: '',
  };

  const [firstImage] = aboutImages;
  const {
    homepageName,
    user: { firstName, lastName },
  } = publicAccount;
  const photographer = homepageName || `${firstName} ${lastName}`;

  let headerText = photographer;
  const { reviewCount } = publicAccount.reviewSummary;
  if (reviewCount > 0) {
    headerText += ` has ${reviewCount} review${reviewCount !== 1 ? 's' : ''}`;
  }

  const renderedIntoSummary = useMemo(() => {
    try {
      const renderedBody = renderTiptapToHtml(introSummary);
      return renderedBody;
    } catch {}

    return typeof introSummary === 'string' ? introSummary : '';
  }, [introSummary]);

  const renderedAboutPageContent = useMemo(() => {
    try {
      const renderedBody = renderTiptapToHtml(aboutPageContent);
      return renderedBody;
    } catch {}

    return typeof aboutPageContent === 'string' ? aboutPageContent : '';
  }, [aboutPageContent]);

  if (publicAccount) {
    return (
      <>
        <div className={style.content}>
          <h1 className={style.heading}>About</h1>
          {firstImage && <CdnImage className={style.firstImage} src={firstImage} />}
          {introSummary && (
            <p
              dangerouslySetInnerHTML={{ __html: renderedIntoSummary }}
              data-testid="intro-summary"
              id="bloom-wysiwyg"
            />
          )}
          <div className={twMerge(style.row, style.imageContainer)}>
            {publicAccount?.aboutImages
              .slice(1)
              .map((url) => url && <CdnImage className={style.image} key={url} src={url} />)}
          </div>
          {aboutPageContent ? (
            <div
              dangerouslySetInnerHTML={{ __html: renderedAboutPageContent }}
              data-testid="about-content"
              id="bloom-wysiwyg"
            />
          ) : (
            <>
              <p dangerouslySetInnerHTML={{ __html: escapeHTML(personalExperience) }} />
              <p dangerouslySetInnerHTML={{ __html: escapeHTML(artistStatement) }} />
            </>
          )}
        </div>

        {listItems && listItems.length > 0 && (
          <div className={style.aboutItemsContainer}>
            <div className={style.content}>
              <h2
                className={style.heading}
                dangerouslySetInnerHTML={{ __html: escapeHTML(listName) }}
              />
              {listItems && (
                <ul className={style.row}>
                  {listItems.map((item) => (
                    <li
                      className={style.listItem}
                      dangerouslySetInnerHTML={{ __html: escapeHTML(item) }}
                      key={item}
                    />
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

        {reviewsSectionOn && (
          <div className={twMerge(style.content, style.reviewsContent)}>
            <h2
              className={style.heading}
              dangerouslySetInnerHTML={{ __html: escapeHTML(headerText) }}
            />
            <LeaveReview onClose={closeReviewModal} open={isReviewModalOpen} />

            <Reviews
              comments={comments}
              isCommentsShown={isCommentsShown}
              onCommentsHide={hideComments}
              onCommentsShow={showComments}
              onLeaveReviewClick={openReviewModal}
              profile={publicAccount}
            />
            <Hyperlink className={style.addReviewButton} onClick={openReviewModal}>
              Leave a review
            </Hyperlink>
          </div>
        )}
      </>
    );
  }

  return null;
};

export default About;
