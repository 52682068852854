import { v4 as uuidv4 } from 'uuid';

import { getCookie } from '@bloom/library/utils/browser';
import { dataUriFromFormField } from '@bloom/library/utils/file';

import { updateUploadProgress } from './actions';
import { UPLOAD, FILE_ID } from './constants';
import { UPLOAD_FILE } from './constants/ActionTypes';

// It stores the number of invocations of `progress` callback
// for each uploading file. It is used to control
// how often progress action is fired.

let progressCache = {};
let progressUpdatedAt = null;
// A queue of files to be uploaded.
let uploadingQueue = [];
// The files being uploaded at the moment.
let uploadingFiles = [];
// The number of uploading files at the moment.
let uploadingCount = 0;
// The maximum number of simultaneously uploading files.
const UPLOAD_LIMIT = 5;

/*
 * This is a Redux middleware,
 * for more details see http://redux.js.org/docs/advanced/Middleware.html
 *
 */
export default ({ dispatch }) =>
  (next) =>
  (action) => {
    const { file, options = {} } = action[UPLOAD] || {};
    const isUploadingInitialization = !!action[UPLOAD];

    if (action.type !== UPLOAD_FILE) {
      return next(action);
    }

    const fileId = uuidv4();

    if (isUploadingInitialization) {
      delete action[UPLOAD];
      uploadingQueue.push({ file, fileId, options });

      dataUriFromFormField(file).then((previewUrl) => {
        dispatch({
          ...action,
          [FILE_ID]: fileId,
          meta: {
            name: file.name,
            size: file.size,
            type: file.type,
          },
          previewUrl,
          status: 'start',
        });
      });
    }

    if (uploadingCount < UPLOAD_LIMIT && uploadingQueue.length > 0) {
      const uploadable = uploadingQueue.shift();

      uploadingCount += 1;

      // To uploading avatar for not logged in user on
      // leaving review page use public token instead.
      const token = getCookie('bloom_token');

      // use temp-credentials/file-resolver-token endpoint to get a token to upload to FileResolver (GET_STORAGE_TOKEN action)
      // https://paper.dropbox.com/doc/temp-credentials--BbIDYjYV_tgRdtWbrXhchupwAg-RXfrizoI1vFCRPImUjJMy#:uid=878566151780349646989112&h2=file-resolver-token
      const sessionToken = sessionStorage.getItem('storage_token');

      const activeAccountId = localStorage.getItem('bloom_active_account_id');

      const form = new FormData();
      form.append('file', uploadable.file);
      Object.keys(uploadable.options).forEach((key) => {
        form.append(key, uploadable.options[key]);
      });

      new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${process.env.BLOOM_FILE}/upload`);
        xhr.setRequestHeader('Authorization', `bearer ${token || sessionToken}`);
        xhr.setRequestHeader('Accept', 'application/json');

        if (activeAccountId) {
          xhr.setRequestHeader('x-account', activeAccountId);
        }
        // xhr.onloadstart = () => {};

        xhr.upload.addEventListener(
          'progress',
          (e) => {
            // Fire progress action every 3rd time or when upload is complete,
            // in order to reduce CPU usage when large number of files are uploaded.
            // if (progressCallbackCount[uploadable.fileId] % 3 === 0 || e.loaded === e.total) {
            //   dispatch({
            //     type: UPLOAD_FILE,
            //     status: 'progress',
            //     [FILE_ID]: uploadable.fileId,
            //     progress: Math.round(100 * e.loaded / e.total),
            //   });
            // }
            // progressCallbackCount[uploadable.fileId] += 1;
            // console.log('progress callback ', uploadable.fileId, ': ', e.timeStamp);
            progressCache[uploadable.fileId] = Math.round((100 * e.loaded) / e.total);
            if (!progressUpdatedAt || e.timeStamp - progressUpdatedAt > 400) {
              dispatch(updateUploadProgress(progressCache));
              progressUpdatedAt = e.timeStamp;
            }
          },
          false
        );

        xhr.upload.addEventListener(
          'load',
          () => {
            progressCache[uploadable.fileId] = 100;
            dispatch(updateUploadProgress(progressCache));
          },
          false
        );

        xhr.onloadend = (e) => {
          uploadingFiles = uploadingFiles.filter((u) => u.fileId !== uploadable.fileId);
          uploadingCount -= 1;

          const req = e.target;
          const data = req.responseText ? JSON.parse(req.responseText) : 'error';
          if (req.status === 200) {
            resolve(data);
          } else {
            reject(data);
          }
        };
        xhr.send(form);

        uploadingFiles.push({ fileId: uploadable.fileId, xhr });
      })
        .then((response) =>
          dispatch({
            data: response,
            [FILE_ID]: uploadable.fileId,
            status: 'done',
            type: UPLOAD_FILE,
          })
        )
        .catch((error) => {
          dispatch({
            error,
            [FILE_ID]: uploadable.fileId,
            status: 'error',
            type: UPLOAD_FILE,
          });
        });
    }

    // Synchronously return fileId.
    return isUploadingInitialization ? fileId : next(action);
  };
