'use client';
import '@bloom/library/assets/stylesheets/common.css';

import React, { useEffect, useLayoutEffect, useState } from 'react';

import { hydrate, HydrationBoundary, useQueryClient } from '@tanstack/react-query';
import Script from 'next/script';
import { Provider } from 'react-redux';

import { AccountBrandInfoResponse } from '@bloom/codegen/models/AccountBrandInfoResponse';
import { PublicAccountResponse } from '@bloom/codegen/models/PublicAccountResponse';

import { doNothing } from '@bloom/ui/utils/empty-value';

import { FileUploaderProvider } from '@bloom/library/components/hooks/useFileUploader';
import configureStore from '@bloom/library/store/configureStore';

import { setCookie } from '@bloom/library';

const PublicProviders: React.FC<
  React.PropsWithChildren<{
    account?: AccountBrandInfoResponse | PublicAccountResponse;
    dehydratedQueryState?: unknown;
    store?: unknown;
    messenger?: 'enabled' | 'disabled' | 'disabled-on-mobile';
    messengerAutoOpen?: boolean;
  }>
> = (props) => {
  const {
    account,
    children,
    dehydratedQueryState = {},
    messenger = 'enabled',
    messengerAutoOpen,
    store = {},
  } = props;

  const queryClient = useQueryClient();

  hydrate(queryClient, dehydratedQueryState);

  const reduxStore = configureStore(store);

  const [messengerState, setMessengerState] = useState<
    'enabled' | 'disabled' | 'disabled-on-mobile'
  >(messenger);

  useLayoutEffect(() => {
    if (messengerAutoOpen === false) {
      const date = new Date();
      date.setHours(date.getHours() + 2);
      const expires = date.toUTCString();
      // Don't auto popup in the next 2 hours
      setCookie('isPopupAutoTriggered', 'true', expires);
    }
  }, [messengerAutoOpen]);

  useEffect(() => {
    if (messenger === 'disabled-on-mobile') {
      const mediaQuery = window.matchMedia('(min-width: 768px)');

      if (mediaQuery.matches && messengerState !== 'enabled') {
        setMessengerState('enabled');
      }

      function handleMediaQueryChange(e: MediaQueryListEvent) {
        setMessengerState(e.matches ? 'enabled' : 'disabled-on-mobile');
      }

      mediaQuery.addEventListener('change', handleMediaQueryChange);
      return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
      };
    }

    return doNothing;
  }, [messenger, messengerState]);

  return (
    <>
      <Provider store={reduxStore}>
        <HydrationBoundary state={dehydratedQueryState}>
          <FileUploaderProvider>{children}</FileUploaderProvider>
        </HydrationBoundary>
      </Provider>

      {messengerState === 'enabled' && account ? (
        <Script
          dangerouslySetInnerHTML={{
            __html: `
      window.bloomSettings = { profileId: "${account.id}", userId: "${'ownerId' in account ? account.ownerId : account.user.id}" };
      const bloomScript = document.createElement('script');
      bloomScript.src = "https://${process.env.BLOOM_WIDGET_SUBDOMAIN}.${process.env.BLOOM_DOMAIN}/widget.js?v=${process.env.BLOOM_BUILD_ID}";
      bloomScript.type = 'module';
      document.head.appendChild(bloomScript);`,
          }}
        />
      ) : null}
    </>
  );
};

export { PublicProviders };
