'use client';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useImageFallback } from '@bloom/library/components/hooks/useImageFallback';
import { getSizeSuffix, getImageUrl } from '@bloom/library/utils/image';

const LowResImage: React.FC<{ source: string }> = (props) => {
  const { source } = props;
  const { src } = useImageFallback(source, 'XS');
  return <img className="relative z-10 w-full scale-[1.02] blur-xs" src={src} />;
};

const HigResImage: React.FC<{
  maxHeight: number;
  onLoad: () => void;
  originalSource: string;
  timeout: number;
  wrapperRef: React.MutableRefObject<HTMLDivElement>;
}> = (props) => {
  const { maxHeight, onLoad, originalSource, timeout, wrapperRef } = props;

  const [ratio, setRatio] = useState(1);

  const [isHighRezLoaded, setHighRezLoaded] = useState(false);
  const [highRezSrc, setHighRezSrc] = useState('');

  const imageRef = useRef<HTMLImageElement>();

  const handleHighRezLoaded = useCallback(() => {
    setHighRezLoaded(true);
    if (typeof onLoad === 'function') {
      onLoad();
    }
    imageRef.current.removeEventListener('load', handleHighRezLoaded);
  }, [onLoad]);

  const preloadHighRez = useCallback(() => {
    const suffix = getSizeSuffix(window.innerWidth);
    const source = getImageUrl(originalSource, suffix);
    imageRef.current = document.createElement('img');
    imageRef.current.src = source;
    imageRef.current.addEventListener('load', handleHighRezLoaded);
    setHighRezSrc(source);
  }, [handleHighRezLoaded, originalSource]);

  const updateFirstImageDimensions = useCallback(() => {
    if (wrapperRef.current) {
      const newWrapperHeight = Math.min(wrapperRef.current.parentNode?.offsetWidth / ratio, 800);
      const newWrapperWidth = newWrapperHeight * ratio;
      wrapperRef.current.style.height = `${newWrapperHeight}px`;
      wrapperRef.current.style.width = `${newWrapperWidth}px`;
    }
  }, [ratio, wrapperRef]);

  useEffect(() => {
    if (maxHeight) {
      const img = new Image();
      img.src = getImageUrl(originalSource, 'XS');
      window.addEventListener('resize', updateFirstImageDimensions);
      img.onload = () => {
        setRatio(img.width / img.height);
        updateFirstImageDimensions();
      };
      img.onerror = () => {
        img.src = originalSource;
      };
    }
  }, [maxHeight, originalSource, updateFirstImageDimensions]);

  useEffect(() => {
    if (originalSource) {
      preloadHighRez();
    }
  }, [preloadHighRez, originalSource]);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', updateFirstImageDimensions);
    };
  }, [updateFirstImageDimensions]);

  return highRezSrc ? (
    <img
      className={twMerge(
        'absolute top-0 left-0 z-20 w-full',
        isHighRezLoaded ? 'opacity-100' : 'opacity-0'
      )}
      src={highRezSrc}
      style={{ transition: `opacity ${timeout}ms ease-in` }}
    />
  ) : null;
};
interface IProps {
  className?: string;
  maxHeight?: number;
  onLoad?: () => void;
  src: string;
  styles: React.CSSProperties;
  // in milliseconds
  timeout: number;
}

const CdnImage: React.FC<IProps> = (props) => {
  const { className, maxHeight, onLoad, src, styles, timeout = 800 } = props;

  const wrapperRef = useRef<HTMLDivElement>();

  return (
    <div
      className={twMerge('relative w-full max-w-full overflow-hidden', className)}
      ref={wrapperRef}
      style={styles}
    >
      <LowResImage source={src} />

      <HigResImage
        maxHeight={maxHeight}
        onLoad={onLoad}
        originalSource={src}
        timeout={timeout}
        wrapperRef={wrapperRef}
      />
    </div>
  );
};

export default CdnImage;
