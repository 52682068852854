import React from 'react';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';

export default (WrappedComponent) => {
  function CustomColor(props) {
    const customColor = useCustomColor();

    return <WrappedComponent {...props} customColor={customColor} />;
  }

  return CustomColor;
};
