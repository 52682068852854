import React from 'react';

import { IIconProps } from './types';

const MessageIcon: React.FC<IIconProps> = ({
  width = 20,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      clipRule="evenodd"
      d="M4.167 3.333a.833.833 0 00-.834.834v11.321l1.911-1.91a.833.833 0 01.59-.245h10a.833.833 0 00.833-.833V4.167a.833.833 0 00-.834-.834H4.167zM2.399 2.4a2.5 2.5 0 011.768-.732h11.666a2.5 2.5 0 012.5 2.5V12.5a2.5 2.5 0 01-2.5 2.5H6.18l-3.09 3.09a.834.834 0 01-1.422-.59V4.167a2.5 2.5 0 01.732-1.768z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { MessageIcon };
