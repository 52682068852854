import { UPLOAD } from './constants';
import { UPDATE_UPLOAD_PROGRESS, UPLOAD_FILE } from './constants/ActionTypes';

export function uploadFile(file, options = {}, userDetails) {
  return {
    type: UPLOAD_FILE,
    userDetails,
    [UPLOAD]: {
      file,
      options,
    },
  };
}

// Batch update
export function updateUploadProgress(data) {
  return {
    type: UPDATE_UPLOAD_PROGRESS,
    data,
  };
}
