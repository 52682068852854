import fileSaver from 'file-saver';

import { DEFAULT_ERROR_MESSAGE } from '@bloom/library/components/FlashMessageV2/constants';
import { getCookie } from '@bloom/library/utils/browser';

const { saveAs } = fileSaver;

export const FETCH = 'FETCH_RESOURCE';
export const ASYNC = 'ASYNC_ACTION';

/*
 * This is a Redux middleware,
 * for more details see http://redux.js.org/docs/advanced/Middleware.html
 *
 * Its primary goal is to handle ajax requests in a simple and standardized way.
 * It processes only actions with FETCH key specified, others just passed to the
 * next middleware.
 * The value corresponsing to the FETCH key is an object. It __must__ have
 * at least one property, i.e url. Optionally it may have other properties
 * which mirror .fetch() options.
 * The status field is used to indicate the action status. It takes the following
 * values: 'start', 'done', 'error'. Which should be self-explanatory.
 *
 */
export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const fetchConfig = action[FETCH];

    if (fetchConfig === undefined) {
      return next(action);
    }

    let { url } = fetchConfig;
    let modifiedConfig;

    // Use Picr API
    if (url.startsWith('/api')) {
      url = process.env.BLOOM_API + url;
      const { method, headers = {}, body, ...restConfig } = fetchConfig;

      // Set Authorization header if a user is logged in.
      const token = getCookie('bloom_token');

      if (typeof headers.Authorization === 'undefined' && token) {
        headers.Authorization = `bearer ${token}`;
      }

      const activeAccountId = localStorage.getItem('bloom_active_account_id');

      if (activeAccountId) {
        headers['x-account'] = activeAccountId;
      }

      modifiedConfig = {
        method: method || 'GET',
        // TODO maybe set 'same-origin' in production?
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.bloom.v3',
          'Content-Type': 'application/json',
          ...headers,
        },
        ...restConfig,
      };

      if (body) {
        if (process.env.NODE_ENV === 'development') {
          if (typeof body !== 'object') {
            console.error(`The type of body property should be an object.`); // eslint-disable-line
          }
        }
        modifiedConfig.body = JSON.stringify(body);
      }
    }

    delete action[FETCH];
    // Mark the action with ASYNC key
    // for better error handling.
    action[ASYNC] = true;

    dispatch({ ...action, status: 'start' });

    return fetch(url, modifiedConfig || fetchConfig)
      .then((response) => {
        if (response.ok) {
          // Handle the case when response has no content,
          // e.g. after DELETE request.
          if (response.status === 204) {
            return {};
          }

          const contentType = response.headers.get('Content-Type');

          if (contentType.indexOf('text/csv') > -1) {
            return response.blob().then((blob) => saveAs(blob, action.fileName));
          }

          if (contentType.indexOf('application/pdf') > -1) {
            const contentDisposition = response.headers.get('Content-Disposition');
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            // Notice leading comma. Need to match with index 1
            const [, filename] = contentDisposition.match(filenameRegex);

            return response.blob().then((blob) => saveAs(blob, filename));
          }

          // Do not throw an error when reponse has Content-Type: text/html,
          // and has empty body. For example, successful reponses for
          // POST /api/gmail/enable and POST /api/gmail/disable are like this.
          if (contentType.indexOf('text/html') > -1) {
            return {};
          }

          return response.json().then((body) => {
            /**
             * Handle failed request with successful status.
             * For example, `GET /api/purchases/coupons/FAKECODE` endpoint
             * returns 200 OK status and this body:
             * {
             *   "reason":  "Invalid promo code",
             *   "success": false,
             * }
             */
            if (body.success === false) {
              return Promise.reject(new Error(body.reason));
            }
            return body;
          });
        }
        return response.json().then(
          // The response has been parsed successfully, pass it down the chain.
          (error) => Promise.reject(error),
          // Show a meaninglful error message when the parsing failed.
          () => Promise.reject(new Error(DEFAULT_ERROR_MESSAGE))
        );
      })
      .then(
        (response) => next({ ...action, data: response, status: 'done' }),
        (error) => next({ ...action, error, status: 'error' })
      );
  };
