import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

import withCustomColor from '@bloom/library/components/HOC/withCustomColor';
import StarIcon from '@bloom/library/components/Icon/Star';

import style from './Rating.module.scss';

const labels = ['It’s terrible', 'I don’t like it', 'It’s okay', 'I liked it', 'I loved it'];

class Rating extends Component {
  static propTypes = {
    starCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isShowCount: PropTypes.bool,
    className: PropTypes.string,
    customColor: PropTypes.string.isRequired,
    isUsesLabel: PropTypes.bool,
    onRatingChange: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    isShowCount: true,
    isUsesLabel: false,
    onRatingChange: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      // if rating not set yet than startCount === 0
      // which means shonwLabel === labels[-1] === undefined
      // set empty string to avoid render 'undefined' word
      shownLabel: labels[props.starCount - 1] || '',
    };
  }

  handleMouseEnter = (e) => {
    if (!this.props.isUsesLabel) {
      return false;
    }
    const child = e.target;
    const parent = e.target.parentNode;
    const index = Array.prototype.indexOf.call(parent.children, child);
    if (this.state.shownLabel !== labels[index]) {
      this.setState(() => ({ shownLabel: labels[index] }));
    }
  };

  handleMouseLeave = () => {
    if (!this.props.isUsesLabel) {
      return false;
    }
    const { starCount } = this.props;

    if (this.state.shownLabel !== labels[starCount - 1]) {
      this.setState(() => ({ shownLabel: labels[starCount - 1] }));
    }
  };

  render() {
    const { customColor, onRatingChange } = this.props;
    const starComponents = ((starsIn) => {
      const data = [];

      // Round out the star count. (Comes from API as a float. Could be a float. Let's round it off to be safe.)
      const stars = Math.round(starsIn);

      // Put in the highlighted stars
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < stars; i++) {
        data.push(
          // The icon wrapped with span because onMouseEnter event
          // doesn't fire on svg
          <span
            className={style.starWrapper}
            key={data.length}
            onClick={onRatingChange}
            onMouseEnter={this.handleMouseEnter}
          >
            <StarIcon className={style.star} color={customColor} width={20} />
          </span>
        );
      }

      // Put in the non-highlighted stars
      while (data.length < 5) {
        data.push(
          // The icon wrapper with span because onMouseEnter event
          // doesn't fire on svg
          <span
            className={style.starWrapper}
            key={data.length}
            onClick={onRatingChange}
            onMouseEnter={this.handleMouseEnter}
          >
            <StarIcon className={twMerge(style.star, style.inactive)} width={20} />
          </span>
        );
      }
      return data;
    })(this.props.starCount); // IIFE with starCount as input

    return (
      <div className={this.props.className} onMouseLeave={this.handleMouseLeave}>
        <div className="flex items-center">{starComponents}</div>
        {/* use ternary operator instead of "(statement) && return" to avoid return "0" */}
        {this.props.starCount && this.props.isShowCount && !this.props.isUsesLabel ? (
          <span className={style.reviewCount}>({`${this.props.starCount} out of 5 stars`})</span>
        ) : null}
        {/* use ternary operator instead of "(statement) && return" to avoid return "0" */}
        {this.props.isUsesLabel ? (
          <span className={style.reviewLabel}>{this.state.shownLabel}</span>
        ) : null}
      </div>
    );
  }
}

export default withCustomColor(Rating);
