// Do not remove this file, it is used in unit test custom render

import { createStore, applyMiddleware, compose } from 'redux';

import fetchMiddleware from '@bloom/library/components/Fetch/middleware';
import uploadMiddleware from '@bloom/library/components/Upload/middleware';

import rootReducer from './rootReducer';

const middlewares = [fetchMiddleware, uploadMiddleware];

export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middlewares)));

  return store;
}
