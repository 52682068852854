'use client';
import React, { useMemo } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { twMerge } from 'tailwind-merge';

import { PoweredBy } from '@bloom/ui/components/PoweredBy';

import DownButton from '@bloom/library/components/Button/DownButton';
import SecondaryButton from '@bloom/library/components/Button/SecondaryButton';
import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import SocialLinks from '@bloom/library/components/Nav/SocialLinks';
import { renderTiptapToHtml } from '@bloom/library/components/Tiptap/template.server';
import { getBusinessNameFromAccount } from '@bloom/library/utils/misc';
import { escapeHTML } from '@bloom/library/utils/string';

import { FormButtonWrapper } from '../FormButton';

import commonStyle from './Common.module.scss';
import style from './Home.module.scss';

const Home: React.FC = () => {
  const router = useRouter();

  const customColor = useCustomColor();

  const { publicAccount } = usePublicAccountByCustomUrl();

  const introSummary = publicAccount?.introSummary || '';

  function handleDownClick() {
    router.push('/about');
  }

  function handleSeePortfolioClick() {
    router.push('/portfolio');
  }

  const renderedIntoSummary = useMemo(() => {
    try {
      const renderedBody = renderTiptapToHtml(introSummary);
      return renderedBody;
    } catch {}

    return typeof introSummary === 'string' ? introSummary : '';
  }, [introSummary]);

  if (publicAccount) {
    return (
      <div className={commonStyle.wrapper}>
        <section className={twMerge(style.content, !introSummary ? style.empty : '')}>
          <header>
            <span className={style.welcome} style={{ color: customColor }}>
              Welcome
            </span>
            <h1
              dangerouslySetInnerHTML={{
                __html: escapeHTML(getBusinessNameFromAccount(publicAccount)),
              }}
            />
            <SocialLinks className={style.social} links={publicAccount.socialMediaLinks} />
          </header>
          <p
            className={style.introSummary}
            dangerouslySetInnerHTML={{ __html: renderedIntoSummary }}
            data-testid="intro-summary"
          />

          <div className={style.ctaButtons}>
            <SecondaryButton onClick={handleSeePortfolioClick}>See Gallery</SecondaryButton>

            <FormButtonWrapper />
          </div>
        </section>
        <DownButton className={style.downButton} onClick={handleDownClick} />
        <nav className={style.legal}>
          <Link href="/legal/client-terms">Terms</Link>
          <Link href="/legal/privacy">Privacy</Link>

          {publicAccount.isBrandingRemoved ? null : (
            <PoweredBy isBrandingRemoved={false} logoWidth={36} utm_medium="website" />
          )}
        </nav>
      </div>
    );
  }

  return null;
};

export default Home;
