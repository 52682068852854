'use client';
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ArrowRightIcon from '@bloom/library/components/Icon/ArrowRight';

import Button from './Button';

import style from './DownButton.module.scss';
import { twMerge } from 'tailwind-merge';

export default class DownButton extends Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className, ...restProps } = this.props;
    return (
      <Button className={twMerge(style.downButton, className)} {...restProps}>
        <span className={style.arrowWrapper}>
          <ArrowRightIcon className={style.arrow} />
        </span>
      </Button>
    );
  }
}
