import { combineReducers } from 'redux';

import fetchReducer from '@bloom/library/components/Fetch/reducer';
import uploadReducer from '@bloom/library/components/Upload/reducer';

// settings is populated during server-side rendering.
function settings(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  fetch: fetchReducer,
  settings,
  upload: uploadReducer,
  website: combineReducers({
    data: (state = {}) => state,
  }),
});

export default rootReducer;
