export function dataUriFromFormField(file: File): Promise<string> {
  return new Promise((resolve) => {
    const render = new FileReader();

    render.addEventListener('load', () => {
      resolve(render.result as string);
    });

    render.readAsDataURL(file);
  });
}
