import { FILE_ID } from './constants';
import { UPDATE_UPLOAD_PROGRESS } from './constants/ActionTypes';

function progress(state = {}, action) {
  const fileId = action[FILE_ID];
  switch (action.status) {
    case 'start':
      return { ...state, [fileId]: 0 };
    case 'progress':
      return { ...state, [fileId]: action.progress > 95 ? 98 : action.progress };
    case 'done':
      return { ...state, [fileId]: 100 };
    default:
      return state;
  }
}

function files(state = {}, action) {
  const fileId = action[FILE_ID];
  switch (action.status) {
    case 'done':
      return { ...state, [fileId]: action.data.file };
    default:
      return state;
  }
}

function previews(state = {}, action) {
  const fileId = action[FILE_ID];
  switch (action.status) {
    case 'start':
      return { ...state, [fileId]: action.previewUrl };
    default:
      return state;
  }
}

function meta(state = {}, action) {
  const fileId = action[FILE_ID];
  const { status } = action;
  switch (status) {
    case 'start':
      return { ...state, [fileId]: { ...action.meta, status } };
    case 'done':
    case 'error':
      return { ...state, [fileId]: { ...state[fileId], status } };
    default:
      return state;
  }
}

const initialState = {
  progress: {},
  files: {},
  previews: {},
  meta: {},
  isUploading: false,
};

export default function uploader(state = initialState, action) {
  const fileId = action[FILE_ID];

  // Batch update
  if (action.type === UPDATE_UPLOAD_PROGRESS) {
    return { ...state, progress: { ...state.progress, ...action.data } };
  }

  if (!fileId) {
    return state;
  }

  const nextProgress = progress(state.progress, action);

  return {
    progress: nextProgress,
    files: files(state.files, action),
    previews: previews(state.previews, action),
    meta: meta(state.meta, action),
    isUploading:
      action.status === 'start' ||
      action.status === 'progress' ||
      Object.keys(nextProgress).some((id) => nextProgress[id] < 100),
  };
}
