'use client';
import React, { useRef, useState } from 'react';

import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import Map from '@bloom/library/components/Map';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './Location.module.scss';

const Location: React.FC = () => {
  const { publicAccount } = usePublicAccountByCustomUrl();
  const policy = publicAccount?.location.policy;
  const zipcode = publicAccount?.location.zip;

  const [maxHeight, setMaxHeight] = useState<number | undefined>();

  const policyOverlayRef = useRef<HTMLDivElement>(null);

  function handlePolicyClick() {
    const selection = window.getSelection();

    // prevent box collapsing on highlight/select text
    if (maxHeight && selection?.toString().length !== 0) {
      return;
    }

    if (policyOverlayRef.current) {
      const { paddingTop, paddingBottom } = window.getComputedStyle(policyOverlayRef.current, null);
      const fullHeight =
        policyOverlayRef.current.children[0].scrollHeight +
        parseInt(paddingTop, 10) +
        parseInt(paddingBottom, 10);

      setMaxHeight(!maxHeight && fullHeight > 70 ? fullHeight : undefined);
    }
  }

  return (
    <section className={style.content}>
      <Map address={zipcode} />

      {policy && (
        <div
          className={style.policyOverlay}
          data-testid="travel-policy"
          onClick={handlePolicyClick}
          ref={policyOverlayRef}
          style={{ maxHeight }}
        >
          <span dangerouslySetInnerHTML={{ __html: escapeHTML(policy) }} />
        </div>
      )}
    </section>
  );
};

export default Location;
