__turbopack_context__.v({
  "content": "Home-module-scss-module__hUMsTa__content",
  "ctaButtons": "Home-module-scss-module__hUMsTa__ctaButtons",
  "downButton": "Home-module-scss-module__hUMsTa__downButton",
  "empty": "Home-module-scss-module__hUMsTa__empty",
  "introSummary": "Home-module-scss-module__hUMsTa__introSummary",
  "legal": "Home-module-scss-module__hUMsTa__legal",
  "social": "Home-module-scss-module__hUMsTa__social",
  "welcome": "Home-module-scss-module__hUMsTa__welcome",
});
