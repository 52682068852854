'use client';
import React, { useEffect, useRef, useState } from 'react';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  Placement,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTypeahead,
} from '@floating-ui/react';
import { twMerge } from 'tailwind-merge';

import { IconButton } from '@bloom/ui/components/IconButton';
import { CheckIcon } from '@bloom/ui/components/Icons/Check';
import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';
import { CloseIcon } from '@bloom/ui/components/Icons/Close';
import { IIconProps } from '@bloom/ui/components/Icons/types';
import { Input } from '@bloom/ui/components/Input';
import { MenuItem } from '@bloom/ui/components/MenuItem';
import { Tooltip } from '@bloom/ui/components/Tooltip';
import { doNothing } from '@bloom/ui/utils/empty-value';
import { mergeRefs } from '@bloom/ui/utils/misc';

export interface IOptionProps {
  className?: string;
  disabled?: boolean;
  disabledMessage?: React.ReactNode;
  icon?: React.ComponentType<IIconProps>;
  label: string;
  value: string;
}

export const Select: React.FC<
  Partial<Omit<React.ComponentProps<typeof Input>, 'onChange' | 'value'>> & {
    MenuItem?: React.ComponentType<unknown>;
    children?: React.ReactNode;
    'data-testid': string;
    inputClassName?: string;
    isClearable?: boolean;
    isOpenMenuByDefault?: boolean;
    loading?: boolean;
    markerColor?: string;
    matchParentWidth?: boolean;
    menuClassName?: string;
    minMenuWidth?: number;
    name: string;
    noItemsPlaceholder?: string;
    onChange: (name: string, value: string) => void;
    onOpenChange?: (open: boolean) => void;
    open?: boolean;
    options: Readonly<Array<IOptionProps>>;
    placement?: Placement;
    strategy?: 'fixed' | 'absolute';
    typeaheadEnabled?: boolean;
    value: string;
    visibleOptions?: number;
  }
> = (props) => {
  const {
    children,
    ['data-testid']: dataTestId,
    inputClassName,
    invalid,
    isClearable,
    isOpenMenuByDefault,
    markerColor,
    matchParentWidth = true,
    menuClassName,
    MenuItem: CustomMenuItem,
    minMenuWidth = 0,
    noItemsPlaceholder = 'No options',
    onChange,
    onOpenChange: setControlledOpen,
    open: controlledOpen,
    options,
    placement = 'bottom-start',
    strategy = 'fixed',
    typeaheadEnabled = true,
    value,
    visibleOptions = 10,
    ...restInputProps
  } = props;
  const [isOpen, setOpenState] = useState(isOpenMenuByDefault);

  const open = controlledOpen ?? isOpen;
  const setOpen = setControlledOpen ?? setOpenState;

  const inputRef = useRef<HTMLInputElement>();

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    options.findIndex((o) => o.value === value)
  );

  useEffect(() => {
    const newIndex = options.findIndex((o) => o.value === value);
    if (newIndex !== selectedIndex) {
      setSelectedIndex(newIndex);
    }
  }, [options, selectedIndex, value]);

  const { context, floatingStyles, refs } = useFloating({
    middleware: [
      offset(8),
      flip({ padding: 10 }),
      size({
        apply({ availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${Math.min(availableHeight, visibleOptions * 32 + 16)}px`,
            width: matchParentWidth
              ? `${Math.max(minMenuWidth, 150, rects.reference.width)}px`
              : undefined,
          });
        },
      }),
    ],
    onOpenChange: setOpen,
    open: open,
    placement,
    strategy,
    whileElementsMounted: autoUpdate,
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef<Array<string>>(options.map((o) => o.value));
  const isTypingRef = useRef(false);

  const click = useClick(context, { event: 'mousedown' });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'listbox' });
  const listNav = useListNavigation(context, {
    activeIndex,
    listRef,
    // This is a large list, allow looping.
    loop: true,
    onNavigate: setActiveIndex,
    selectedIndex,
    virtual: true,
  });

  const typeahead = useTypeahead(context, {
    activeIndex,
    enabled: typeaheadEnabled,
    listRef: listContentRef,
    onMatch: open ? setActiveIndex : doNothing,
    onTypingChange(isTyping) {
      isTypingRef.current = isTyping;
    },
    selectedIndex,
  });

  const { getFloatingProps, getItemProps, getReferenceProps } = useInteractions([
    dismiss,
    role,
    listNav,
    typeahead,
    click,
  ]);

  const handleSelect = (_: number, value: string) => {
    onChange(restInputProps.name, value);
    setOpen(false);
  };

  function handleClearClick() {
    onChange(restInputProps.name, '');
  }

  const inputValue =
    typeof value !== 'undefined' ? options.find((o) => o.value === value)?.label || value : '';

  const ref = mergeRefs(refs.setReference, inputRef);

  return (
    <>
      <Input
        Control={isClearable && inputValue ? null : <ChevronIcon />}
        aria-autocomplete="none"
        aria-labelledby="select-label"
        data-testid={`${dataTestId}-input`}
        inputClassName={twMerge('pr-10 cursor-default truncate', inputClassName)}
        invalid={invalid}
        onChange={doNothing}
        tabIndex={0}
        {...getReferenceProps({ ...restInputProps })}
        ref={ref}
        value={inputValue}
      >
        {isClearable && inputValue ? (
          <IconButton
            className="absolute top-1/2 right-1 -translate-y-1/2"
            data-testid={`${dataTestId}-clear-button`}
            onClick={handleClearClick}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </Input>

      {open ? (
        <FloatingPortal root={inputRef?.current?.ownerDocument.body}>
          <FloatingFocusManager context={context} modal={false}>
            {React.isValidElement(children) ? (
              <div ref={refs.setFloating} style={{ ...floatingStyles }} {...getFloatingProps()}>
                {React.cloneElement(children, {
                  ...children.props,
                  onClose: () => setOpen(false),
                  options,
                  selectedIndex,
                })}
              </div>
            ) : (
              <div
                className={twMerge(
                  'font-regular flex w-full flex-col overflow-y-auto rounded-lg bg-white p-2 shadow-lg outline-hidden',
                  menuClassName
                )}
                data-testid={`${dataTestId}-menu`}
                ref={refs.setFloating}
                style={{ ...floatingStyles }}
                {...getFloatingProps()}
              >
                {options.length === 0 ? (
                  <MenuItem
                    className="text-grey truncate italic"
                    data-testid={
                      props['data-testid'] ? `${props['data-testid']}-no-items` : undefined
                    }
                    disabled
                  >
                    {noItemsPlaceholder}
                  </MenuItem>
                ) : null}
                {options.map((option, i) => {
                  const {
                    disabled,
                    disabledMessage,
                    icon: Icon,
                    label,
                    value: optionValue,
                  } = option;

                  const menuItemProps = {
                    'aria-selected': i === selectedIndex && i === activeIndex,
                    as: 'button',
                    className: twMerge(
                      i === activeIndex ? 'bg-light-grey' : '',
                      i === selectedIndex ? 'font-medium' : '',
                      option.className
                    ),
                    'data-testid': `${props['data-testid']}-menu-item-${i}`,
                    disabled,
                    key: optionValue,
                    ref: (node) => {
                      listRef.current[i] = node;
                    },
                    role: 'option',
                    tabIndex: i === activeIndex ? 0 : -1,
                    ...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect(i, option.value);
                      },
                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(i, option.value);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(i, option.value);
                        }
                      },
                    }),
                  };

                  if (CustomMenuItem) {
                    return <CustomMenuItem {...menuItemProps} {...option} />;
                  }
                  return (
                    <Tooltip
                      asChild
                      className="flex w-full justify-between"
                      content={disabled ? disabledMessage : ''}
                      offsetProps={{ mainAxis: 20 }}
                      placement="top-end"
                    >
                      <MenuItem {...menuItemProps}>
                        {React.isValidElement(Icon)
                          ? React.cloneElement(Icon, { width: 16, ...Icon.props })
                          : null}
                        <span className="truncate">{label}</span>
                        {i === selectedIndex ? (
                          <CheckIcon
                            className="ml-auto w-4 shrink-0"
                            color={markerColor || 'var(--color-primary)'}
                          />
                        ) : null}
                      </MenuItem>
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </FloatingFocusManager>
        </FloatingPortal>
      ) : null}
    </>
  );
};
