'use client';
import React, { useMemo, useState } from 'react';

import Link from 'next/link';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Modal } from '@bloom/ui/components/Modal';
import { doNothing } from '@bloom/ui/utils/empty-value';

import { SLIDESHOW } from '@bloom/library/components/Gallery/constants';
import Grid from '@bloom/library/components/Gallery/Grid';
import Slideshow from '@bloom/library/components/Gallery/Slideshow';
import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import VideoPlayer from '@bloom/library/components/VideoPlayer/VideoPlayer';
import { getProviderByLink } from '@bloom/library/utils/misc';

import { usePortfolio } from '@bloom/portal/components/hooks/usePortfolio';
import { FormButtonWrapper } from '@bloom/portal/containers/public/pages/templates/minimalist/FormButton';

import style from './Home.module.scss';

const Home: React.FC = () => {
  const [selectedImageId, setSelectedImageId] = useState('');
  const [isSlideshowVisible, { setFalse: hideSlideshow, setTrue: showSlideshow }] = useToggle();

  const { publicAccount } = usePublicAccountByCustomUrl();

  const activeTemplate = useActiveTemplate();

  const slideshowGallery = publicAccount?.slideshowGallery;

  const [{ imageArrangement, images }] = slideshowGallery?.albums || [
    { imageArrangement: {}, images: [] },
  ];

  const { data: portfolio } = usePortfolio(publicAccount?.user.id);

  const [isVideoModalOpen, { setFalse: closeVideoModal, setTrue: openVideoModal }] = useToggle();

  const sortedImages = useMemo(() => {
    return images.slice().sort((a, b) => imageArrangement[a.id] - imageArrangement[b.id]);
  }, [imageArrangement, images]);

  function handleImageSelect(imageId: string) {
    setSelectedImageId(imageId);
    showSlideshow();
  }

  // if selected images id is not set then selectedImageIndex will be "-1".
  // the index of -1 causes app crash on slide click
  const selectedImageIndex = Math.max(
    sortedImages.findIndex((i) => i.id === selectedImageId),
    0
  );
  const selectedImage = sortedImages.find((i) => i.id === selectedImageId) || {};

  const isSlideshowGallery = slideshowGallery?.gridType === SLIDESHOW;

  function handleSlideshowClose() {
    setSelectedImageId('');
    hideSlideshow();
  }

  function handlePlayVideoClick(imageId: string) {
    const image = sortedImages.find((i) => i.id === imageId) || {};

    if (image?.videoSource) {
      openVideoModal();
      setSelectedImageId(imageId);
    }
  }

  return (
    <div className={isSlideshowGallery ? style.slideshowContainer : ''}>
      <Grid
        albumId="home-images"
        gridType={slideshowGallery?.gridType}
        images={sortedImages}
        onImageClick={handleImageSelect}
        onPlayEmbedVideo={handlePlayVideoClick}
        portfolio={portfolio}
      />

      {images.length === 0 ? null : (
        <Slideshow
          images={sortedImages}
          isExpanded={isSlideshowVisible}
          onClose={handleSlideshowClose}
          onImageClick={doNothing}
          onPlayEmbedVideo={handlePlayVideoClick}
          selectedIndex={selectedImageIndex}
          template={activeTemplate}
        />
      )}

      <div className={style.ctaButtons}>
        <Link className={style.portfolioLink} href="/portfolio">
          See Gallery
        </Link>

        <FormButtonWrapper />
      </div>

      <Modal className="p-0" onClose={closeVideoModal} open={isVideoModalOpen} size="fullscreen">
        <VideoPlayer
          autoplay
          provider={getProviderByLink(selectedImage)}
          src={selectedImage?.videoSource || ''}
        />
      </Modal>
    </div>
  );
};

export default Home;
