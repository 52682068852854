import { combineReducers } from 'redux';

// Use this instead of a local state,
// in cases when you need to disable some action
// during fetch request.
function isRunning(state = {}, action) {
  switch (action.status) {
    case 'start':
      return { ...state, [action.type]: true };
    case 'error':
    case 'done':
      return { ...state, [action.type]: false };
    default:
      return state;
  }
}

function isDone(state = {}, action) {
  switch (action.status) {
    case 'start':
    case 'error':
      return { ...state, [action.type]: false };
    case 'done':
      return { ...state, [action.type]: true };
    default:
      return state;
  }
}

function isFailed(state = {}, action) {
  switch (action.status) {
    case 'start':
    case 'done':
      return { ...state, [action.type]: false };
    case 'error':
      return { ...state, [action.type]: true };
    default:
      return state;
  }
}

function doneAt(state = {}, action) {
  switch (action.status) {
    case 'done':
      return { ...state, [action.type]: (new Date()).toString() };
    default:
      return state;
  }
}

export default combineReducers({
  isRunning,
  isDone,
  isFailed,
  doneAt,
});
