import React from 'react';

import { twMerge } from 'tailwind-merge';

export enum UserPicSizeEnum {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
  XSMALL = 'xs',
  CUSTOM = 'custom',
}

const UserPic: React.FC<{
  className?: string;
  'data-testid'?: string;
  initials?: string;
  initialsLength?: number;
  size?: UserPicSizeEnum;
  src: string;
}> = (props) => {
  const {
    className,
    initials = '',
    initialsLength = 1,
    size = UserPicSizeEnum.MEDIUM,
    src,
  } = props;

  return (
    <div
      className={twMerge(
        'font-accent relative flex shrink-0 items-center justify-center rounded-full bg-cover bg-center text-white uppercase',
        !src ? 'bg-dark-grey' : '',
        size === UserPicSizeEnum.SMALL ? 'h-10 w-10 text-xs' : '',
        size === UserPicSizeEnum.MEDIUM ? 'h-12 w-12 text-base' : '',
        size === UserPicSizeEnum.LARGE ? 'h-20 w-20 text-2xl' : '',
        size === UserPicSizeEnum.XSMALL ? 'text-2xs h-6 w-6' : '',
        className
      )}
      data-testid={props['data-testid']}
    >
      {src ? (
        <img
          alt={initials.slice(0, Math.min(initialsLength, 2))}
          className="absolute top-0 left-0 h-full w-full rounded-full object-cover object-center before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2"
          data-testid={`${props['data-testid']}-image`}
          src={src}
        />
      ) : (
        initials.slice(0, Math.min(initialsLength, 2))
      )}
    </div>
  );
};

export { UserPic };
