'use client';
import { useRef } from 'react';

import { twMerge } from 'tailwind-merge';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { useWindowSize } from '@bloom/ui/components/hooks/useWindowSize';

import Button from '@bloom/library/components/Button/Button';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import LeaveReview from '@bloom/library/components/Review/LeaveReview';
import Reviews from '@bloom/library/components/Review/Reviews';

import commonStyle from './Common.module.scss';
import style from './Review.module.scss';

const Review: React.FC = () => {
  const { width = 0 } = useWindowSize();
  const isMobile = width < 1024;

  const { publicAccount } = usePublicAccountByCustomUrl();

  const [isModalShown, { setFalse: closeModal, setTrue: openModal }] = useToggle();
  const [isCommentsShown, { setFalse: hideComments, setTrue: showComments }] = useToggle();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const isReachedBoundaryRef = useRef(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);

  function handleWheel(e: React.WheelEvent) {
    // Do not change page if Leave a review modal is open.
    if (isModalShown) {
      e.stopPropagation();
      return;
    }

    // Don't do anything on screens less than 1024px.
    if (isMobile) {
      return;
    }

    if (isCommentsShown && wrapperRef.current && contentRef.current) {
      const { scrollTop } = wrapperRef.current;
      const wrapperBottom = wrapperRef.current.getBoundingClientRect().bottom;
      const reviewBottom = contentRef.current.getBoundingClientRect().bottom;
      // Prevent going to the next page,
      // instead scroll the comments section.
      if (
        // if scrolling down and hasn't reached the bottom
        // (for some reason in the bottom position sectionBottom
        // is still slighly larger than wrapperBottom)
        (e.deltaY > 0 && wrapperBottom < reviewBottom - 1) ||
        // or scrolling up and hasn't reached the top
        (e.deltaY < 0 && scrollTop > 0)
      ) {
        e.stopPropagation();

        // No need to use React state here.
        isReachedBoundaryRef.current = false;
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      } else if (!isReachedBoundaryRef.current) {
        // Clear timeout to compensate for momentum scrolling,
        // i.e. the effect that onScroll event keeps firing
        // after the scrolling has been stopped.
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          isReachedBoundaryRef.current = true;
        }, 90);
      }

      if (!isReachedBoundaryRef.current) {
        e.stopPropagation();
      }
    }
  }

  const {
    homepageName,
    user: { firstName, lastName },
  } = publicAccount;
  const photographer = homepageName || `${firstName} ${lastName}`;
  const contentStyles = {};

  return (
    <div
      className={twMerge(commonStyle.wrapper, style.wrapper)}
      onWheel={handleWheel}
      ref={wrapperRef}
    >
      <div className={style.contentWrapper}>
        <LeaveReview onClose={closeModal} open={isModalShown} />

        <div
          className={twMerge(commonStyle.content, style.content)}
          ref={contentRef}
          style={contentStyles}
        >
          <Reviews
            className={style.reviews}
            isCommentsShown={isCommentsShown}
            onCommentsHide={hideComments}
            onCommentsShow={showComments}
            onLeaveReviewClick={openModal}
            photographer={photographer}
            profile={publicAccount}
          />
          <Button
            className="border-regular bg-lighter-grey hover:bg-light-grey absolute top-0 right-0 rounded-sm border px-2 py-1.5 text-xs"
            onClick={openModal}
          >
            Leave a review
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Review;
