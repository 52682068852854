import React from 'react';

import { IIconProps } from './types';

const DownloadIcon: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => (
  <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
    <path
      d="M10.833 2.5a.833.833 0 10-1.666 0v7.988L6.423 7.744a.833.833 0 10-1.179 1.179l4.166 4.165.008.009a.83.83 0 00.58.236h.004a.831.831 0 00.587-.244l4.167-4.166a.833.833 0 10-1.179-1.179l-2.744 2.744V2.5z"
      fill={color}
    />
    <path
      d="M2.5 11.667c.46 0 .833.373.833.833v3.333a.833.833 0 00.834.834h11.666a.833.833 0 00.834-.834V12.5a.833.833 0 111.666 0v3.333a2.5 2.5 0 01-2.5 2.5H4.167a2.5 2.5 0 01-2.5-2.5V12.5c0-.46.373-.833.833-.833z"
      fill={color}
    />
  </svg>
);
export { DownloadIcon };
