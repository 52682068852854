__turbopack_context__.v({
  "aboutItemsContainer": "About-module-scss-module__8bqYoW__aboutItemsContainer",
  "addReviewButton": "About-module-scss-module__8bqYoW__addReviewButton",
  "content": "About-module-scss-module__8bqYoW__content",
  "firstImage": "About-module-scss-module__8bqYoW__firstImage",
  "heading": "About-module-scss-module__8bqYoW__heading",
  "image": "About-module-scss-module__8bqYoW__image",
  "imageContainer": "About-module-scss-module__8bqYoW__imageContainer",
  "listItem": "About-module-scss-module__8bqYoW__listItem",
  "reviewsContent": "About-module-scss-module__8bqYoW__reviewsContent",
  "row": "About-module-scss-module__8bqYoW__row",
});
