import React, { useEffect, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';

import { useAsyncScript } from '../hooks/useAsyncScript';
import { useCustomColor } from '../hooks/useCustomColor';
import mapStyle from './mapStyle.json';
import mapStyleSimple from './mapStyleSimple.json';

import style from './Map.module.scss';

// Draws Custom Overlay in order to apply expanding animation.
function drawAnimatedCircle(center, radius, color, map) {
  // Constructor
  function CustomCircle(c, r, m) {
    this.map_ = m;
    this.div_ = null;
    this.setMap(m);
  }
  CustomCircle.prototype = new google.maps.OverlayView();
  CustomCircle.prototype.onAdd = function onAdd() {
    const div = document.createElement('div');
    div.style.position = 'absolute';

    const dot = document.createElement('div');
    dot.className = style.dot; // class with custom styling
    dot.style.cssText = `background: ${color}`;
    div.appendChild(dot);
    const wave1 = document.createElement('div');
    wave1.className = style.wave1; // class with custom styling
    div.appendChild(wave1);
    const wave2 = document.createElement('div');
    wave2.className = style.wave2; // class with custom styling
    div.appendChild(wave2);

    this.div_ = div;
    const panes = this.getPanes();
    panes.overlayLayer.appendChild(div);
  };
  CustomCircle.prototype.draw = function draw() {
    const overlayProjection = this.getProjection();
    const sw = overlayProjection.fromLatLngToDivPixel(new google.maps.LatLng(center));
    const ne = overlayProjection.fromLatLngToDivPixel(new google.maps.LatLng(center));
    const div = this.div_;
    div.style.left = sw.x - radius / 2 + 'px';
    div.style.top = ne.y - radius / 2 + 'px';
    div.style.width = radius + 'px';
    div.style.height = radius + 'px';
  };
  CustomCircle.prototype.onRemove = function onRemove() {
    this.div_.parentNode.removeChild(this.div_);
    this.div_ = null;
  };

  return new CustomCircle(center, radius, map);
}

const cache = {};

function getGeoData(address) {
  return new Promise((resolve) => {
    if (cache[address]) {
      return resolve(cache[address]);
    }

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results) => {
      const [firstResult] = results || [];
      if (firstResult) {
        const { location } = firstResult.geometry;
        const coords = { lat: location.lat(), lng: location.lng() };
        cache[address] = {
          addressComponents: firstResult.address_components,
          coords,
          formattedAddress: firstResult.formatted_address,
        };
        resolve(cache[address]);
      }
    });
  });
}

interface IProps {
  address?: string;
  className?: string;
  isSimple?: boolean;
  onChange?: (formattedAddress: string, addressComponents: unknown) => void;
  radius?: number;
  scrollwheel?: boolean;
  zoom?: number;
}

const Map: React.FC<IProps> = (props) => {
  const {
    address = 'Columbia Way, Vancouver, WA 98660, USA',
    className = '',
    isSimple,
    onChange,
    radius = 350,
    scrollwheel,
    zoom: zoomProp = 8,
  } = props;

  const [valid, { setTrue }] = useToggle();

  const { isReady } = useAsyncScript({
    globalName: 'google',
    // Include places lib as well, because AutocompletePlaces
    // won't load the script again if google global var is already defined,
    // but it relies on google.maps.places.SearchBox being defined.
    src: `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.GOOGLE_API_KEY}`,
  });
  const customColor = useCustomColor();

  const [zoom, setZoom] = useState(zoomProp);

  const animatedCircleRef = useRef(null);
  const markerRef = useRef(null);
  const mapRef = useRef(null);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  function initMap(center) {
    if (isReady) {
      mapRef.current = new google.maps.Map(mapContainerRef.current, {
        center,
        // Disable draggin only on touch devices.
        draggable: !('ontouchend' in document),
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        scrollwheel,
        streetViewControl: false,
        styles: isSimple ? mapStyleSimple : mapStyle,
        zoom,
        zoomControl: true,
      });

      mapRef.current.addListener('zoom_changed', () => {
        const newZoom = mapRef.current.getZoom();
        if (newZoom !== zoom) {
          setZoom(newZoom);
        }
      });
    }
  }

  function setMarker(coords, radius) {
    // Set static marker
    if (isSimple) {
      if (markerRef.current) {
        markerRef.current.setMap(null);
      }
      markerRef.current = new google.maps.Marker({
        map: mapRef.current,
        position: coords,
      });
      // Set animated marker
    } else {
      if (animatedCircleRef.current) {
        // Remove previously set animated circle.
        animatedCircleRef.current.setMap(null);
      }
      animatedCircleRef.current = drawAnimatedCircle(coords, radius, customColor, mapRef.current);
    }
  }

  useEffect(() => {
    if (isReady) {
      getGeoData(address).then(({ addressComponents, coords, formattedAddress }) => {
        initMap(coords);
        setMarker(coords, radius);
        setTrue();
        if (onChange) {
          onChange(formattedAddress, addressComponents);
        }
      });
    }
    // call only on mount when the module is ready
  }, [address, isReady]);

  return (
    <div
      className={twMerge('relative h-full w-full', className, valid ? '' : 'hidden')}
      data-testid="map-preview"
      ref={mapContainerRef}
    />
  );
};

export default Map;
