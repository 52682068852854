'use client';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { doNothing, emptyArray } from '@bloom/ui/utils/empty-value';

import { useMatchMedia } from '@bloom/library/components/hooks/use-match-media';
import { usePublicAccountByCustomUrl } from '@bloom/library/components/hooks/usePublicAccount';
import CdnImage from '@bloom/library/components/ProgressiveImage/CdnImage';
import { renderTiptapToHtml } from '@bloom/library/components/Tiptap/template.server';
import { escapeHTML } from '@bloom/library/utils/string';

import style from './About.module.css';
import commonStyle from './Common.module.scss';

const About: React.FC = () => {
  const isMobile = useMatchMedia('(max-width: 767px)');
  const [fits, setFitsState] = useState(true);

  const [isExpanded, { setTrue: expand }] = useToggle();

  const aboutImagesRef = useRef<Record<string, HTMLDivElement>>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { publicAccount } = usePublicAccountByCustomUrl();

  const aboutImages = publicAccount?.aboutImages || emptyArray;
  const aboutPageContent = publicAccount?.aboutPageContent || '';
  const aboutPageTitle = publicAccount?.aboutPageTitle;
  const artistStatement = publicAccount?.artistStatement;
  const personalExperience = publicAccount?.personalExperience;

  const isReachedBoundaryRef = useRef(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        if (entries[0] && entries[0].target.scrollHeight > entries[0].target.clientHeight) {
          setFitsState(false);
        }
      });

      resizeObserver.observe(contentRef.current);

      return () => {
        if (contentRef.current) {
          resizeObserver.disconnect();
        }
      };
    }

    return doNothing;
  }, []);

  function handleImageLoad(url: string) {
    const element = aboutImagesRef.current[url];
    if (!element) {
      return;
    }

    const { height, width } = element.getBoundingClientRect();
    const ratio = width / height;
    const baseWidth = 150;

    element.style.flexGrow = `${ratio}`;
    element.style.flexBasis = `${baseWidth * ratio}px`;
    if (height > width) {
      element.style.maxWidth = `${baseWidth}px`;
    }
  }

  function handleWheel(e: React.WheelEvent) {
    // Don't do anything on screens less than 1024px.
    if (isMobile || !isExpanded || !containerRef.current) {
      return;
    }

    const { scrollHeight, scrollTop } = containerRef.current;
    const { height: wrapperHeight } = containerRef.current.getBoundingClientRect();

    // Prevent going to the next page,
    // instead scroll the comments section.
    if (
      // if scrolling down and hasn't reached the bottom
      // (for some reason in the bottom position sectionBottom
      // is still slighly larger than wrapperBottom)
      (e.deltaY > 0 && scrollTop < scrollHeight - wrapperHeight) ||
      // or scrolling up and hasn't reached the top
      (e.deltaY < 0 && scrollTop > 0)
    ) {
      e.stopPropagation();

      // No need to use React state here.
      isReachedBoundaryRef.current = false;
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    } else if (!isReachedBoundaryRef.current) {
      // Clear timeout to compensate for momentum scrolling,
      // i.e. the effect that onScroll event keeps firing
      // after the scrolling has been stopped.
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        isReachedBoundaryRef.current = true;
      }, 90);
    }

    if (!isReachedBoundaryRef.current) {
      e.stopPropagation();
    }
  }

  const renderedAboutPageContent = useMemo(() => {
    try {
      const renderedBody = renderTiptapToHtml(aboutPageContent);
      return renderedBody;
    } catch {}

    return typeof aboutPageContent === 'string' ? aboutPageContent : '';
  }, [aboutPageContent]);

  return (
    <div
      className={twMerge(commonStyle.wrapper, style.wrapper)}
      onWheel={handleWheel}
      ref={containerRef}
    >
      <section
        className={twMerge(
          style.content,
          'flex flex-col overflow-hidden p-12 md:p-16 lg:p-30',
          !personalExperience && !artistStatement && !aboutPageContent ? style.empty : '',
          !isExpanded ? 'h-full max-h-full overflow-hidden' : ''
        )}
      >
        <div
          className={twMerge(
            !isExpanded ? 'max-h-full overflow-hidden' : '',
            !fits && !isExpanded ? style.collapsed : '',
            'relative m-auto'
          )}
          ref={contentRef}
        >
          <h2
            className={style.firstHeader}
            dangerouslySetInnerHTML={{ __html: escapeHTML(aboutPageTitle || 'About') }}
            data-testid="about-title"
          />
          <div
            dangerouslySetInnerHTML={{ __html: renderedAboutPageContent }}
            data-testid="about-content"
            id="bloom-wysiwyg"
          />
          {/* Legacy code */}
          {!aboutPageContent && personalExperience && artistStatement ? (
            <>
              <p dangerouslySetInnerHTML={{ __html: escapeHTML(personalExperience) }}></p>
              <p dangerouslySetInnerHTML={{ __html: escapeHTML(artistStatement) }}></p>
            </>
          ) : null}

          <div className={style.aboutImages}>
            {aboutImages.map(
              (url) =>
                url && (
                  <div
                    key={url}
                    ref={(node) => {
                      aboutImagesRef.current[url] = node;
                    }}
                  >
                    <CdnImage
                      className={style.image}
                      onLoad={handleImageLoad.bind(null, url)}
                      src={url}
                    />
                  </div>
                )
            )}
          </div>
        </div>
        {!isMobile && !fits && !isExpanded && (
          <button
            className="absolute bottom-14 left-16 text-xs underline decoration-2 underline-offset-8 lg:left-30"
            onClick={expand}
            style={{ textDecorationColor: '#e8e8e8' }}
            type="button"
          >
            Continue Reading
          </button>
        )}
      </section>
    </div>
  );
};

export default About;
